<template>
  <div :class="width ? 'table-container' : 'table-full'" class="ml-th_sortable">
    <v-data-table
      fixed-header
      dark
      :items="items"
      :headers="headers"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :items-per-page="10"
      :loading="isLoading"
      :multi-sort="true"
      :hide-default-footer="true"
      :custom-sort="handleCustomSort"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :class="width ? 'table-custom custom-width' : 'table-custom'"
      @update:options="handleOptions"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <td class="text-start v-data-table__divider show-full" > 
        <div class="icon-action">
          <img class="hover-icon" v-if="iconDetail" :src="ICON_DETAIL" @click="handleView(item)" />
          <img v-if="iconEdit && (tables === 'user-management' || (tables === 'user-group-management' && item.user_group !== 'Superadmin'))" :class="disabled ? 'disabled-icon' : 'hover-icon'" :src="ICON_EDIT" @click="disabled ? null : handleEdit(item)" />
          <img v-if="iconDelete" :class="disabled ? 'disabled-icon' : 'hover-icon'" :src="ICON_DELETE" @click="disabled ? null : handleDelete(item)" />
        </div>
        </td>
      </template>
      <template v-slot:[`item.web_view`]="{ item }">
        <td class="text-start v-data-table__divider show-full" >
          <v-tooltip top v-for="(value, index) in item.web_view" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <img  :src="getImagePath(value)" alt="" >
              </v-btn>
            </template>
            <span>{{ tranferItemName(value) }}</span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:[`item.web_control`]="{ item }">
        <td class="text-start v-data-table__divider show-full">
          <v-tooltip top v-for="(value, index) in item.web_control" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <img  :src="getImagePath(value)" alt="" >
              </v-btn>
            </template>
            <span>{{ tranferItemName(value) }}</span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:[`item.app_view`]="{ item }">
        <td class="text-start v-data-table__divider show-full">
          <v-tooltip top v-for="(value, index) in item.app_view" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <img  :src="getImagePath(value)" alt="" >
              </v-btn>
            </template>
            <span>{{ tranferItemName(value) }}</span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:[`item.app_control`]="{ item }">
        <td class="text-start v-data-table__divider show-full">
          <v-tooltip top v-for="(value, index) in item.app_control" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <img  :src="getImagePath(value)" alt="" >
              </v-btn>
            </template>
            <span>{{ tranferItemName(value) }}</span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:no-data>
        <div class="no-data-container">
          <img :src="iconNotFound" alt="No Data" />
          <div>No data found!</div>
        </div>
      </template>
    </v-data-table>
    <div v-if="pagination" class="pagination">
      <v-pagination
        v-model="currentPage"
        :length="Math.ceil(total / 10)"
        :total-visible="7"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { tranferItem } from '@/utils/common';
import {
  ICON_NOT_FOUND,
  ICON_DELETE,
  ICON_EDIT,
  ICON_DETAIL,
} from '@/theme/image';
export default {
  data() {
    return {
      iconNotFound: ICON_NOT_FOUND,
      currentPage: 1,
      ICON_DETAIL,
      ICON_DELETE,
      ICON_EDIT,
    };
  },
  watch: {
    page: {
      handler(newVal) {
        this.currentPage = newVal;
      },
    },
  },
  methods: {
    getImagePath(value) {
      return require(`../assets/${value}.svg`);
    },
    tranferItemName(name) {
      return tranferItem(name)
    },
    handleView(item) {
      this.$emit('view', item);
    },
    handleEdit(item) {
      this.$emit('edit', item);
    },
    handleDelete(item) {
      this.$emit('delete', item);
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.$emit('on-change-page', value);
    },
    totalPages() {
      return Math.ceil(this.items.length / 10);
    },
    handleOptions(options) {
      this.$emit('on-sort', {
        sort_filter: options?.sortBy,
        sort: options?.sortDesc.map(item => (item ? 'DESC' : 'ASC')),
      });
    },
    handleCustomSort() {
      return this.items;
    }
  },
  props: {
    onClick: {
      type: Function,
      default: () => { },
    },
    lengthPage: {
      type: String,
      required: false,
    },
    total: {
      type: Number,
      default: 10,
    },
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    sortBy: {
      type: String || Array,
    },
    sortDesc: {
      type: String || Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    noData: {
      type: String,
      default: 'No data found',
    },
    iconDetail: {
      type: Boolean,
      default: true,
    },
    iconEdit: {
      type: Boolean,
      default: true,
    },
    iconDelete: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean
    },
    page: {
      type: Number
    },
    tables: {
      type: String,
      default: 'user-management',
    },
  },
};
</script>

<style lang="scss">
.table-container {
  max-width: 1024px;
  width: 100%;

  .custom-width {
    width: 100%;
  }
}
.table-full{
  width: 100%;
}
.table-custom .v-data-table__wrapper tbody tr:hover {
  background-color: #000000 !important;
}
.table-custom tbody tr:nth-child(even) {
  background-color: #2c2c2e80;
}

.table-custom tbody tr:nth-child(odd) {
  background-color: #3e3e40;
}

.ml-th_sortable {
  th {
    font-weight: 900;
    background-color: #131313;
    color: #FFFFFFE5 !important;
    white-space: nowrap;
    height: 64px !important;
    font-family: 'DMSansBold';
  }
}

td {
  font-weight: 400;
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: 14px !important;
}

th,
td {
  color: #FFFFFFB2;
  text-align: center !important;
}

.pagination {
  margin-top: 16px;
  justify-content: flex-end;
}

.no-data-container {
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.theme--light.v-pagination .v-pagination__item{
  font-size: 14px;
}
.theme--light.v-pagination .v-pagination__item,
.theme--light.v-pagination .v-pagination__navigation {
  background: #1c1c1e !important;
  color: #8e8e93 !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff !important;
  background: #3A3A3C !important;
}

.theme--dark.v-data-table.v-data-table--fixed-header thead th{
  border: thin solid #707070 !important;
}

.theme--dark.v-data-table .v-data-table__divider{
  border: thin solid #707070 !important;
}

.icon-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.hover-icon {
  cursor: pointer;
}

.hover-icon:hover {
  filter: brightness(90);
}

.no-data-img {
  margin-bottom: 1rem;
}

.theme--dark.v-data-table .v-data-table-header__sort-badge {
  background-color: hsla(0, 0%, 100%, 0.12);
  color: #fff;
  display: none;
}

.custom-width {
  max-width: 1024px;
  width: 100%;
}

.v-data-table .v-data-table-header tr th {
  font-size: 14px !important;
}

.ml-th_sortable .v-data-table-header th.sortable {
  span {
    margin-left: 24px;
  }
}

.v-data-table-header .mdi-menu-up::before {
  font-size: 32px;
}

.table-custom table tbody tr {
  td {
    max-width: 265px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.show-full{
      white-space: unset;
      overflow: unset;
    }
    .theme--dark.v-btn.v-btn--has-bg {
      padding: 0;
      height: auto;
      background-color: transparent;
      min-width: unset;
      all: initial;
    }
    img{
      width: 24px;
      height: 24px;
      margin-right: 2px;
    }
  }
}
.table-custom .v-data-table__wrapper{
  max-height: 65vh;
}
.disabled-icon {
  cursor: not-allowed;
}

.table-custom::-webkit-scrollbar, .v-data-table__wrapper::-webkit-scrollbar{
    width: 8px;
    height: 8px;
  }
  
/* Track */
.table-custom::-webkit-scrollbar-track, .v-data-table__wrapper::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.table-custom::-webkit-scrollbar-thumb, .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #636366;
  border-radius: 20px;
}
  
 /* Handle on hover */

.table-custom::-webkit-scrollbar-thumb:hover, .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
    background: #636366;
  }
</style>
