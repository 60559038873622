// import ICON_CLOCK from '../assets/icon-clock.png';
import ICON_ACTIVE from '../assets/icon-active.png';
import ICON_INACTIVE from '../assets/icon-inactive.png';
import ICON_CLOCK from '../assets/icon-clock.png';
import ICON_CLOCK_ACTIVE from '../assets/icon-clock-active.png';
import ICON_MANAGEMENT_USER from '../assets/icon-management-user.png';
import ICON_MANAGEMENT_USER_ACTIVE from '../assets/icon-management-user-active.png';
import ICON_SETTINGS_USER from '../assets/icon-settings-user.png';
import ICON_SETTINGS_USER_ACTIVE from '../assets/icon-settings-user-active.png';
import ICON_GLOBAL from '../assets/icon-global.png';
import ICON_GLOBAL_ACTIVE from '../assets/icon-global-active.png';
import ICON_HOME from '../assets/icon-home.png';
import ICON_HOME_ACTIVE from '../assets/icon-home-active.png';
import ICON_CHART from '../assets/icon-chart.png';
import ICON_CHART_ACTIVE from '../assets/icon-chart-active.png';
import ICON_ENERGY from '../assets/icon-energy.png';
import ICON_ENERGY_ACTIVE from '../assets/icon-energy-active.png';
import ICON_WARNING from '../assets/icon-warning.png';
import ICON_WARNING_ACTIVE from '../assets/icon-warning-active.png';
import ICON_DELETE from '../assets/icon-bin.png';
import ICON_EDIT from '../assets/icon-edit.png';
import ICON_NOT_FOUND from '../assets/icon-not-found.png';
import ICON_DETAIL from '../assets/icon-eye.png';
export {
  ICON_ACTIVE,
  ICON_INACTIVE,
  ICON_GLOBAL,
  ICON_GLOBAL_ACTIVE,
  ICON_HOME,
  ICON_HOME_ACTIVE,
  ICON_CHART,
  ICON_CHART_ACTIVE,
  ICON_ENERGY,
  ICON_ENERGY_ACTIVE,
  ICON_WARNING,
  ICON_WARNING_ACTIVE,
  ICON_CLOCK,
  ICON_CLOCK_ACTIVE,
  ICON_MANAGEMENT_USER,
  ICON_MANAGEMENT_USER_ACTIVE,
  ICON_SETTINGS_USER,
  ICON_SETTINGS_USER_ACTIVE,
  ICON_DELETE,
  ICON_EDIT,
  ICON_NOT_FOUND,
  ICON_DETAIL,
};
